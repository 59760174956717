<template>
  <b-nav-item-dropdown
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="dropdown-user"
    :class="userData.impersonated ? 'bg-danger' : ''"
  >
    <template #button-content>
      <div class="d-sm-flex d-none user-nav">
        <b-spinner v-if="loadingUser" small type="grow"></b-spinner>
        <p
          v-else
          class="user-name font-weight-bolder mb-0"
        >
          {{ userData.name }}
        </p>
        <span class="user-status">{{ userData.role }}</span>
      </div>
      <b-avatar
        size="40"
        :src="userData.picture"
        variant="light-primary"
        badge
        class="badge-minimal"
        badge-variant="success"
      >
        <feather-icon v-if="!userData.name" icon="UserIcon" size="22" />
      </b-avatar>
    </template>

    <b-dropdown-item
      :to="{ name: 'pages-account-setting' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon size="16" icon="UserIcon" class="mr-50" />
      <span>Contul meu</span>
    </b-dropdown-item>

    <b-dropdown-divider />

    <b-dropdown-item
      v-if="userData.impersonated"
      link-class="d-flex align-items-center"
      @click="stopImpersonating"
    >
      <feather-icon size="16" icon="UserCheckIcon" class="mr-50" />
      <span>Detașare</span>
    </b-dropdown-item>

    <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
      <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
      <span>Logout</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
  BSpinner,
} from 'bootstrap-vue'

import { avatarText } from '@core/utils/filter'

import store from '@/store'
import config from '@/config'
import axios from '@axios'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    BSpinner,
  },
  data() {
    return {
      avatarText,
      loadingUser: false,
    }
  },
  created() {
    if (!store.state.auth.user) {
      this.$router.push({ name: 'auth-login' })
    }
  },
  methods: {
    async logout() {
      await this.$store
        .dispatch('auth/logout')
        .then(() => this.$router.push({ path: config.LOGIN_ROUTE }))
    },

    async stopImpersonating() {
      this.loadingUser = true
      axios
        .get('/user/stop-impersonating')
        .then(async () => {
          await this.$store.dispatch('auth/getUserData')
        })
        .catch((e) => console.log(e.message))
        .finally(() => {
          this.loadingUser = false
        })
    },
  },

  computed: {
    userData: function () {
      return (
        this.$store.state.auth.user || {
          name: null,
        }
      )
    },
  },
}
</script>

<style>
  .bg-danger p{
    color: #fff;
  }
</style>