<template>
  <layout-vertical>
    <router-view :key="this.$store.state.auth.user.company_id"></router-view>
  </layout-vertical>
</template>

<script>
import LayoutVertical from '@core/layouts/layout-vertical/LayoutVertical.vue'

export default {
  components: {
    LayoutVertical,
  },
}
</script>
