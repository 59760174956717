<template>
  <li
    v-if="isVisible()"
    class="nav-item has-sub section-header text-truncate"
    :class="{
      open: isOpen,
      disabled: item.disabled,
      'sidebar-group-active': isActive,
      item: isActive,
      'horeca': item.section === 'Horeca'
    }"
  >
    <b-link @click="() => updateGroupOpen(!isOpen)">
      <span class="nav-item text-truncate">{{ item.section }}</span>

      <!-- {{ item.section }} -->
    </b-link>
    <b-collapse v-model="isOpen" class="navigation navigation-main" tag="ul">
      <component
        :is="resolveNavItemComponent(child)"
        v-for="child in item.children"
        :key="child.section"
        ref="groupChild"
        :item="child"
      />
    </b-collapse>
  </li>
</template>

<script>
import { BLink, BBadge, BCollapse } from 'bootstrap-vue'
import { resolveVerticalNavMenuItemComponent as resolveNavItemComponent } from '@core/layouts/utils'
import VerticalNavMenuHeader from '../vertical-nav-menu-header'
import VerticalNavMenuLink from '../vertical-nav-menu-link/VerticalNavMenuLink.vue'
import VerticalNavMenuGroup from '../vertical-nav-menu-group/VerticalNavMenuGroup.vue'

// Composition Function
import useVerticalNavMenuGroup from '../vertical-nav-menu-group/useVerticalNavMenuGroup'
import mixinVerticalNavMenuGroup from '../vertical-nav-menu-group/mixinVerticalNavMenuGroup'

export default {
  name: 'VerticalNavMenuSection',
  components: {
    VerticalNavMenuHeader,
    VerticalNavMenuLink,
    VerticalNavMenuGroup,
    BLink,
    BBadge,
    BCollapse,
  },
  mixins: [mixinVerticalNavMenuGroup],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const {
      isOpen,
      isActive,
      isVisible,
      updateGroupOpen,
      updateIsActive,
    } = useVerticalNavMenuGroup(props.item)

    return {
      resolveNavItemComponent,
      isOpen,
      isActive,
      isVisible,
      updateGroupOpen,
      updateIsActive,
    }
  },
}
</script>

<style>
.section-header > a {
  color: #a6a4b0 !important;
  line-height: 1.5;
  letter-spacing: 0.01rem;
  padding: 12px 22px;
  font-size: 0.9rem;
  text-transform: uppercase;
  font-family: Montserrat, Helvetica, Arial, serif;
  font-weight: 500;
}

.main-menu.menu-light .navigation > li > ul li {
  margin: 0 2px;
  font-size: 0.95rem;
}
.vertical-layout.vertical-menu-modern .main-menu .navigation > li > a svg,
.vertical-layout.vertical-menu-modern .main-menu .navigation > li > a i {
  margin-right: 0.8rem;
}
.main-menu.menu-light .navigation > li ul li > a {
  padding: 10px;
}
.main-menu.menu-light .navigation > li > ul li:not(.has-sub) {
  margin: 0;
}
.main-menu.menu-light .navigation > li ul .active {
  background: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 0;
  z-index: 1;
}
.main-menu.menu-light li.horeca .navigation li.active > a {
  background: #ff5b48 !important;
  -webkit-box-shadow: 0 0 10px 1px rgb(255 91 72);
  box-shadow: 0 0 10px 1px rgb(255 91 72);
}
.main-menu.menu-light li.horeca > a, .main-menu.menu-light .navigation li.horeca.sidebar-group-active > a {
  background: #ff7c6c !important;
  color: #fff !important;
  border-radius: 6px;
}
.main-menu.menu-light li.horeca li:nth-last-child(-n+6) a{
  color: #c1c1c3;
}
.main-menu.menu-light li.horeca li:nth-last-child(-n+6).active a{
  color: #fff;
  background: #ff5b4899 !important;
}
</style>