<template>
  <div class="navbar-container d-flex content align-items-center">
    <div
      class="companiesWrapper"
      v-if="role === 'admin' || role === 'consultant'"
    >
      <label class="mb-0 mr-1" for="companies" inline>Alege compania: </label>
      <v-select
        id="companies"
        style="width: 500px"
        label="name"
        v-model="selected"
        :options="companies"
      ></v-select>

      <b-button class="ml-2" variant="outline-primary" @click="addCompany">
        Adaugă companie
      </b-button>
    </div>

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>
    <b-navbar-nav class="nav align-items-center ml-auto">
      <!--<dark-Toggler class="d-none d-lg-block" />-->
      <user-dropdown />
    </b-navbar-nav>
  </div>
</template>

<script>
import { BLink, BNavbarNav, BButton } from 'bootstrap-vue'

import DarkToggler from './components/DarkToggler.vue'
import UserDropdown from './components/UserDropdown.vue'

import vSelect from 'vue-select'

import axios from '@axios'
import config from '@/config'

export default {
  components: {
    BLink,
    BNavbarNav,
    DarkToggler,
    UserDropdown,
    vSelect,
    BButton,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      role: this.$store.state.auth.user.role,
      selected: null,
      companies: [],
    }
  },

  beforeCreate() {
    axios.get(config.API_ENDPOINTS.userCompanies).then(({ data }) => {
      this.companies = data.data

      const selectedCompany = this.$store.state.auth.user.company_id
      this.selected = this.companies.find(
        company => company.id === selectedCompany
      )
    })
  },

  mounted() {
    this.$root.$on('newCompanyCreated', () => {
      this.$store.dispatch('auth/getUserData').then(() => {
        this.updateDropdown()
      })
    })
  },

  watch: {
    selected: {
      handler: function(selected) {
        if (
          selected &&
          selected.id !== this.$store.state.auth.user.company_id
        ) {
          axios
            .post(config.API_ENDPOINTS.updateContext, {
              company_id: selected.id,
            })
            .then(({ data }) => {
              this.$store.dispatch('auth/getUserData').then(() => {
                this.$router
                  .push({
                    name: 'prezentare-generala',
                  })
                  .catch(() => {})
              })
            })
        }
      },
    },
  },

  methods: {
    updateDropdown() {
      axios.get(config.API_ENDPOINTS.userCompanies).then(({ data }) => {
        this.companies = data.data

        const selectedCompany = this.$store.state.auth.user.company_id
        this.selected = this.companies.find(
          company => company.id === selectedCompany
        )
      })
    },

    addCompany() {
      this.selected = null
      axios
        .post(config.API_ENDPOINTS.updateContext, { company_id: null })
        .then(() => {

          this.$store.dispatch('auth/getUserData').then(() => {
                this.$router
                  .push({
                    name: 'm2-manager',
                  })
                  .catch(() => {})
              })
            .catch(() => {})
        })
    },
  },
}
</script>

<style scoped>
.companiesWrapper {
  display: flex;
  align-items: center;
}
.companiesWrapper label {
  font-weight: bold;
}
</style>
