export default [
  {
    section: 'Contul meu',
    children: [
      {
        title: 'Contul Meu',
        route: 'pages-account-setting',
        icon: 'UserIcon',
      },
      {
        title: 'To do',
        route: 'apps-todo',
        icon: 'CheckSquareIcon',
      },
      {
        title: 'Suport tehnic',
        route: 'suport',
        icon: 'InfoIcon',
      },
    ],
  },
  {
    section: 'Administrare',
    role: 'admin',
    children: [
      {
        title: 'Documente utile',
        route: 'apps-documente-utile-list',
        icon: 'none',
      },
      {
        title: 'Știri și comunicate',
        route: 'apps-stiri-list',
        icon: 'none',
      },
      {
        title: 'Întrebări și răspunsuri',
        route: 'apps-faq-list',
        icon: 'none',
      },
      {
        title: 'Q & A exclusive SMIS',
        route: 'apps-faq-smis-list',
        icon: 'none',
      },
      {
        title: 'Descarcă Clarificari',
        route: 'apps-clarificari-smis-list',
        icon: 'none',
      },
        {
            title: 'Știri procedura HORECA',
            route: 'horeca-stiri-list',
            icon: 'none',
        },
    ],
  },
    {
        section: 'Horeca',
        children: [
            {
                title: 'Prezentare program',
                icon: 'HomeIcon',
                route: 'horeca-prezentare-program',
            },
            {
                title: 'Eligibilitate',
                route: 'horeca-eligibilitate',
                icon: 'GridIcon',
            },
            {
                title: 'Obligații',
                route: 'horeca-obligatii',
                icon: 'InfoIcon',
            },
            {
                title: 'Calculează ajutor',
                route: 'horeca-calculeaza-ajutor',
                icon: 'DollarSignIcon',
            },
            {
                title: 'Caută expert / auditor',
                route: 'horeca-expert-auditor',
                icon: 'SearchIcon',
            },
            {
                title: 'Documente procedură',
                route: 'horeca-documente-procedura',
                icon: 'FileTextIcon',
            },
            {
                title: 'Știri procedura HORECA',
                route: 'horeca-stiri-procedura',
                icon: 'BookOpenIcon',
            },
            {
                title: 'Pași pentru depunere',
                route: 'horeca-pasi-depunere',
                icon: 'MapIcon',
            },
            {
                title: 'Contacte AIMMAIPE',
                route: 'horeca-contacte-aimmaipe',
                icon: 'MailIcon',
            },
            {
                title: 'Tutorial video depunere',
                route: 'horeca-tutoriale-video-depunere',
                icon: 'VideoIcon',
            },
            {
                title: 'Întrebări implementare',
                route: 'horeca-intrebari-implementare',
                icon: 'HelpCircleIcon',
            },
            {
                title: 'Monografia contabilă',
                route: 'horeca-monografia-contabila',
                icon: 'FileTextIcon',
            },
            {
                title: 'Identitate vizuală',
                route: 'horeca-identitate-vizuala',
                icon: 'LayoutIcon',
            },
            {
                title: 'Grantul meu',
                route: 'horeca-grantul-meu',
                icon: 'EditIcon',
            },
            {
                title: 'Tutorial raport progres',
                route: 'horeca-tutorial-raport-progres',
                icon: 'BarChart2Icon',
            },
            {
                title: 'Formulare clarificări',
                route: 'horeca-formulare-clarificari',
                icon: 'MessageSquareIcon',
            },
        ],
    },
  {
      section: 'M2 Basic',
      children: [
          {
              title: 'Prezentare generală',
              icon: 'HomeIcon',
              route: 'prezentare-generala',
          },
          {
              title: 'Grantul meu',
              route: 'm2-manager',
              icon: 'EditIcon',
          },
          {
              title: 'Identitate vizuală',
              route: 'm2-identitate-vizuala',
              icon: 'LayoutIcon',
          }
      ],
  },
  {
    section: 'M2 Manager',
    children: [
      {
          title: 'Catalog cheltuieli',
          route: 'catalog-cheltuieli',
          icon: 'CheckCircleIcon',
      },
      {
          title: 'Întrebări Măsura 2',
          route: 'intrebari-m2',
          icon: 'Edit3Icon',
      },
      {
        title: 'Formulare clarificări',
        route: 'm2-clarificari',
        icon: 'PenToolIcon',
      },
      {
        title: 'Contacte finanțator',
        route: 'm2-contacte-finantator',
        icon: 'MailIcon',
      },
      {
        title: 'Calculator valutar',
        route: 'calculator-valutar',
        icon: 'PackageIcon',
      },
      {
        title: 'Modele de documente',
        route: 'modele-documente',
        icon: 'CopyIcon',
      },
      {
          title: 'Neplătitor TVA',
          route: 'neplatitor-tva',
          icon: 'FileTextIcon',
      },
      {
          title: 'Tutoriale video',
          route: 'tutoriale-video',
          icon: 'EyeIcon',
      },
      {
          title: 'Monografia contabilă',
          route: 'monografia-contabila',
          icon: 'FileTextIcon',
      },
      {
          title: 'Grant necheltuit',
          route: 'grant-necheltuit',
          icon: 'ChevronsLeftIcon',
      },
        {
            title: 'Furnizori',
            route: 'furnizori',
            icon: 'ShoppingCartIcon',
            tag: 'Nou',
            tagVariant: 'dark',
        },
      {
        title: 'Comunicat final',
        route: 'comunicat-final',
        icon: 'CheckIcon',
      },
      // {
      //   title: 'Factură & contract',
      //   route: 'factura-contract',
      //   icon: 'CreditCardIcon',
      // },
      // {
      //   title: 'Facilități M2 Standard',
      //   route: 'facilitati-m2-standard',
      //   icon: 'ShoppingCartIcon',
      // },
    ],
  },
  // {
  //   section: 'M2 Manager Premium',
  //   children: [
  //     {
  //       title: 'Modul implementare',
  //       route: 'modul-implementare',
  //       icon: 'GridIcon',
  //     },
  //     {
  //       title: 'Live Chat SMIS.ro',
  //       route: 'live-chat',
  //       icon: 'MessageSquareIcon',
  //     },
  //     {
  //       title: 'Consultant dedicat',
  //       route: 'consultant-dedicat',
  //       icon: 'UsersIcon',
  //     },
  //     {
  //       title: 'Poliță de garantare',
  //       route: 'polita-garantare',
  //       icon: 'ShieldIcon',
  //     },
  //     {
  //       title: 'Facilități M2 Premium',
  //       route: 'facilitati-m2-premium',
  //       icon: 'ShoppingCartIcon',
  //     },
  //   ],
  // },

  {
    section: 'POCU Manager',
    children: [
      {
        title: 'Proiectul Meu',
        route: 'pocu-proiectul-meu',
        icon: 'PlusCircleIcon',
      },
      {
        title: 'Documentele Mele',
        route: 'pocu-documentele-mele',
        icon: 'CopyIcon',
      },
      {
        title: 'Prezentare Buget',
        icon: 'EyeIcon',
        children: [
          {
            title: 'Prezentare Buget',
            route: 'pocu-prezentare-buget',
          },
          {
            title: 'Execuția Bugetară',
            route: 'pocu-executia-bugetara',
          },
        ],
      },
      {
        title: 'Linii de buget',
        icon: 'ServerIcon',
        children: [
          {
            title: 'Linii de buget',
            route: 'pocu-linii-bugetare',
          },
          {
            title: 'Cheltuieli Avizate',
            route: 'pocu-cheltuieli-avizate',
          },
        ],
      },
      {
        icon: 'CheckCircleIcon',
        title: 'Export execuție bugetară',
        route: 'pocu-export-executie-bugetara',
      },
    ],
  },
]
